import { render, staticRenderFns } from "./NowNav.vue?vue&type=template&id=775d9eb8&scoped=true&"
import script from "./NowNav.vue?vue&type=script&lang=js&"
export * from "./NowNav.vue?vue&type=script&lang=js&"
import style0 from "./NowNav.vue?vue&type=style&index=0&id=775d9eb8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775d9eb8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowNavSubscription: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowNavSubscription.vue').default,NowNavNotification: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowNavNotification.vue').default,NowNavAccount: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowNavAccount.vue').default,NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowIcon.vue').default})
