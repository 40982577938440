import { render, staticRenderFns } from "./NowDialog.vue?vue&type=template&id=6bdcf085&scoped=true&"
import script from "./NowDialog.vue?vue&type=script&lang=js&"
export * from "./NowDialog.vue?vue&type=script&lang=js&"
import style0 from "./NowDialog.vue?vue&type=style&index=0&id=6bdcf085&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdcf085",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowIcon.vue').default,NowInput: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowInput.vue').default,NowButton: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowButton.vue').default,NowModal: require('/home/forge/dev.isoudvikling.com/releases/20250226102904/components/NowModal.vue').default})
