import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12688878 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _46684061 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _72e668cd = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _efb216b4 = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _45117dcc = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _088fadd4 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _678eaeb4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _665e749c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _b150d32a = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _72005606 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _6684a9c4 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _2c09a88e = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _ce4a1c42 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _7e7e65ba = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _c853fd5a = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _182045ec = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _19acdaaf = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _1caf5712 = () => interopDefault(import('../pages/supplier/index.vue' /* webpackChunkName: "pages/supplier/index" */))
const _63bc7620 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _887e93a2 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _5020da38 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _b465d774 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _43a4dee5 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _5204c10a = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _55888648 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _ef3f6972 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _cd2984b2 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _46722606 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _22e7df90 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _635d4d89 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _532b1a36 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _206b3a01 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _bbc0689c = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _1b2566e2 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _7978e4c6 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _112c43e8 = () => interopDefault(import('../pages/supplier/type/index.vue' /* webpackChunkName: "pages/supplier/type/index" */))
const _6c8d9e40 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _570f7a68 = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _18ed66de = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _8fc25a92 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _198ac901 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _631a054a = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _1fda13a5 = () => interopDefault(import('../pages/risk/_id/index.vue' /* webpackChunkName: "pages/risk/_id/index" */))
const _37a9f3cc = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _0a77483c = () => interopDefault(import('../pages/supplier/_id/index.vue' /* webpackChunkName: "pages/supplier/_id/index" */))
const _59e5219a = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _a5715f8c = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _3a1ee150 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _1fb6844c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _12688878,
    name: "account"
  }, {
    path: "/action",
    component: _46684061,
    name: "action"
  }, {
    path: "/archive",
    component: _72e668cd,
    name: "archive"
  }, {
    path: "/audit",
    component: _efb216b4,
    name: "audit"
  }, {
    path: "/deviation",
    component: _45117dcc,
    name: "deviation"
  }, {
    path: "/it",
    component: _088fadd4,
    name: "it"
  }, {
    path: "/login",
    component: _678eaeb4,
    name: "login"
  }, {
    path: "/logout",
    component: _665e749c,
    name: "logout"
  }, {
    path: "/offline",
    component: _b150d32a,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _72005606,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _6684a9c4,
    name: "organization"
  }, {
    path: "/register",
    component: _2c09a88e,
    name: "register"
  }, {
    path: "/report",
    component: _ce4a1c42,
    name: "report"
  }, {
    path: "/risk",
    component: _7e7e65ba,
    name: "risk"
  }, {
    path: "/search",
    component: _c853fd5a,
    name: "search"
  }, {
    path: "/styles",
    component: _182045ec,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _19acdaaf,
    name: "suggestion"
  }, {
    path: "/supplier",
    component: _1caf5712,
    name: "supplier"
  }, {
    path: "/task",
    component: _63bc7620,
    name: "task"
  }, {
    path: "/unit",
    component: _887e93a2,
    name: "unit"
  }, {
    path: "/verify",
    component: _5020da38,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _b465d774,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _43a4dee5,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _5204c10a,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _55888648,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _ef3f6972,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _cd2984b2,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _46722606,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _22e7df90,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _635d4d89,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _532b1a36,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _206b3a01,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _bbc0689c,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _1b2566e2,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _7978e4c6,
    name: "password-reset"
  }, {
    path: "/supplier/type",
    component: _112c43e8,
    name: "supplier-type"
  }, {
    path: "/task/group",
    component: _6c8d9e40,
    name: "task-group"
  }, {
    path: "/action/:id",
    component: _570f7a68,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _18ed66de,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _8fc25a92,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _198ac901,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _631a054a,
    name: "report-id"
  }, {
    path: "/risk/:id",
    component: _1fda13a5,
    name: "risk-id"
  }, {
    path: "/suggestion/:id",
    component: _37a9f3cc,
    name: "suggestion-id"
  }, {
    path: "/supplier/:id",
    component: _0a77483c,
    name: "supplier-id"
  }, {
    path: "/unit/:id",
    component: _59e5219a,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _a5715f8c,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _3a1ee150,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _1fb6844c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
